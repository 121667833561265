<template>
	<div>
		<section v-if="changeStockUnit === 0">
			<span><b>Add new Stock Unit</b></span>
			<b-field>
				<b-input
					placeholder="Enter new Stock Unit"
					type="text"
					required
					validation-message="Field is required and the type must be number"
					v-model="newStockUnit"
					@keyup.native.enter="onSubmit()"
				>
				</b-input>
			</b-field>
			<b-button class="is-primary" type="submit" @click="onSubmit()"
				>Add
			</b-button>
		</section>
		<section v-if="changeStockUnit !== 0">
			<span><b>Change the Stock Unit</b></span>
			<b-field>
				<b-input
					placeholder="Enter new value"
					type="text"
					required
					validation-message="Field is required and the type must be number"
					v-model="changeNewValue"
					@keyup.native.enter="onUpdate()"
				>
				</b-input>
			</b-field>
			<b-button class="is-primary" inverted type="submit" @click="onUpdate()"
				>Change
			</b-button>
		</section>
		<div>
			<h2 v-if="loading">Loading...</h2>
		</div>
		<div class="margin-top-15">
			<span><b>List of the Stock Units</b></span>
			<ul v-if="allStockUnits">
				<li
					v-for="item of allStockUnits"
					:key="item.stock_groups"
					class="list-item"
				>
					<b-button
						class="is-primary small-btn"
						outlined
						size="is-small"
						@click="
							deleteStockUnit({ stock_unit: item.stock_unit }) &&
								refreshGroups()
						"
					>
						Delete
					</b-button>
					<b-button
						class="is-primary small-btn"
						outlined
						size="is-small"
						@click="editStockUnit(item.stock_unit)"
					>
						Edit
					</b-button>
					<b>{{ item.stock_unit }}</b>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { useMutation, useQuery, useResult } from '@vue/apollo-composable'

import GetAllStockUnit from '@/_srcv2/pages/stock/graphql/getAllStockUnit.query.gql'
import addStockUnitMutation from '@/_srcv2/pages/stock/graphql/addStockUnit.mutation.gql'
// import allStockUnitSubscription from '../../../graphql/subscriptions/subscribeAllStockUnit.subscription.gql'
import deleteStockUnitMutation from '@/_srcv2/pages/stock/graphql/deleteStockUnit.mutation.gql'
import updateStockUnitMutation from '@/_srcv2/pages/stock/graphql/getAllStockUnit.query.gql'

import Store from '@/store'

export default {
	name: 'stock-unit',
	setup() {
		const { result, loading, refetch } = useQuery(GetAllStockUnit)
		const allStockUnits = useResult(result, [])
		const newStockUnit = ref('')
		const { mutate: deleteStockUnit } = useMutation(deleteStockUnitMutation)
		const { mutate: updateStockUnit } = useMutation(updateStockUnitMutation)
		const { mutate: addNewStockUnit, onDone } = useMutation(
			addStockUnitMutation,
			() => ({
				variables: {
					stock_unit: newStockUnit.value,
				},
			}),
		)
		onDone(() => {
			newStockUnit.value = ''
		})
		function onSubmit() {
			try {
				addNewStockUnit()
			} catch (err) {
				alert('can not be saved')
			}
			refreshGroups()
		}
		const changeStockUnit = ref(0)
		const changeNewValue = ref(0)
		function editStockUnit(unit) {
			changeStockUnit.value = unit
			changeNewValue.value = unit
		}
		function onUpdate() {
			updateStockUnit({
				stock_unit: changeStockUnit.value,
				new_value: changeNewValue.value,
			})
			refreshGroups()
			changeStockUnit.value = 0
			changeNewValue.value = 0
		}
		// refresh data
		const refreshGroups = () => {
			refetch()
			let listArr
			setTimeout(() => {
				listArr = allStockUnits.value.map((item) => item.stock_unit)
				Store.dispatch('setStockUnitDropDown', listArr)
				Store.dispatch('setKeyStockReload', Store.getters.getKeyStockReload + 1)
			}, 1000)
		}
		refreshGroups()
		return {
			result,
			allStockUnits,
			loading,
			refreshGroups,
			addNewStockUnit,
			newStockUnit,
			deleteStockUnit,
			onSubmit,
			changeStockUnit,
			editStockUnit,
			updateStockUnit,
			changeNewValue,
			onUpdate,
		}
	},
}
</script>

<style scoped>
.list-item {
	margin-top: 10px;
}
.margin-top-15 {
	margin-top: 15px;
}
.small-btn {
	margin: 0 11px 0 0;
}
</style>
