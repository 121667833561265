/** @format */

import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const searchInsufficientStockIem = reactive({
	columns: [
		{
			label: 'Stock Id',
			field: 'stockId',
			width: '48px',
			tdClass: 'center-header',
		},
		{
			label: 'Stock Item Name',
			field: 'stockItemName',
			width: '208px',
			tdClass: 'center-header',
		},
		{
			label: 'Digital Inventory',
			field: 'digitalInventory',
			width: '48px',
			tdClass: 'center-header',
		},
		{
			label: 'Stock Alarm Amount',
			field: 'stockAlarmAmount',
			width: '48px',
			tdClass: 'center-header',
		},
	],
})

export default function useInsufficientStockItemState() {
	return {
		...toRefs(searchInsufficientStockIem),
	}
}
