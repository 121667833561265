<!-- @format -->

<template>
	<LayoutWide>
		<div>
			<sub-navbar page="Stock" />
		</div>
		<div style="margin-top: 35px">
			<b-tabs type="is-toggle-rounded">
				<b-tab-item label="Stock Groups">
					<stock-groups />
				</b-tab-item>
				<b-tab-item label="Stock Units">
					<stock-units />
				</b-tab-item>
				<b-tab-item label="Vat Rates">
					<vat-rates />
				</b-tab-item>
			</b-tabs>
		</div>
	</LayoutWide>
</template>

<script>
// ? import Store from '@/store'
import StockGroups from './components/stockgroups'
import StockUnits from './components/stockunits'
import VatRates from './components/vatrates'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
export default {
	name: 'Stock',
	components: {
		StockGroups,
		StockUnits,
		VatRates,
		SubNavbar,
	},
	setup() {},
}
</script>

<style scoped></style>
