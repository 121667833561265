<template>
	<div>
		<section v-if="changeStockGroup === 0">
			<span><b>Add new Stock Group</b></span>
			<b-field>
				<b-input
					placeholder="Enter new Stock Group"
					type="text"
					required
					validation-message="Field is required and the type must be number"
					v-model="newStockGroup"
					@keyup.native.enter="onSubmit()"
				>
				</b-input>
			</b-field>
			<b-button class="is-primary" type="submit" @click="onSubmit()"
				>Add
			</b-button>
		</section>
		<section v-if="changeStockGroup !== 0">
			<span><b>Change the Stock Group</b></span>
			<b-field>
				<b-input
					placeholder="Enter new value"
					type="text"
					required
					validation-message="Field is required and the type must be number"
					v-model="changeNewValue"
					@keyup.native.enter="onUpdate()"
				>
				</b-input>
			</b-field>
			<b-button class="is-primary" inverted type="submit" @click="onUpdate()"
				>Update
			</b-button>
		</section>
		<div>
			<h2 v-if="loading">Loading...</h2>
		</div>
		<div class="margin-top-15">
			<span><b>List of the Stock Groups</b></span>
			<ul v-if="allStockGroups">
				<li
					v-for="item of allStockGroups"
					:key="item.stock_groups"
					class="list-item"
				>
					<b-button
						class="is-primary small-btn"
						outlined
						size="is-small"
						@click="
							deleteStockGroup({
								stock_groups: item.stock_groups,
								our_company: item.our_company,
							}) && refreshGroups()
						"
					>
						Delete
					</b-button>
					<b-button
						class="is-primary small-btn"
						outlined
						size="is-small"
						@click="editStockGroup(item.stock_groups)"
					>
						Edit
					</b-button>
					<b>{{ item.stock_groups }}</b>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'

import { useMutation, useResult, useQuery } from '@vue/apollo-composable'

import GetAllStockGroups from '@/_srcv2/pages/stock/graphql/getAllStockGroups.query.gql'
import addStockGroupsMutation from '@/_srcv2/pages/stock/graphql/addStockGroups.mutation.gql'
import deleteStockGroupsMutation from '@/_srcv2/pages/stock/graphql/deleteStockGroups.mutation.gql'
import updateStockGroupsMutation from '@/_srcv2/pages/stock/graphql/updateStockGroups.mutation.gql'

import Store from '@/store'

export default {
	name: 'stock-groups',
	setup() {
		const ourCompany = computed(() => Store.getters.getUserCurrentCompany)
		// const { result, loading, refetch } = useQuery(GetAllStockGroups)
		// const allStockGroups = useResult(result, [])
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { result, loading, refetch } = useQuery(
			GetAllStockGroups,
			() => ({
				our_company: ourCompany.value,
			}),
			options,
		)
		const allStockGroups = useResult(
			result,
			null,
			(data) => data._type_stock_groups,
		)
		console.log('allStockGroups', allStockGroups)
		const newStockGroup = ref('')
		const { mutate: deleteStockGroup } = useMutation(deleteStockGroupsMutation)
		const { mutate: updateStockGroup } = useMutation(updateStockGroupsMutation)
		const { mutate: addNewStockGroup, onDone } = useMutation(
			addStockGroupsMutation,
			() => ({
				variables: {
					stock_groups: newStockGroup.value,
					our_company: ourCompany.value,
				},
			}),
		)
		onDone(() => {
			newStockGroup.value = ''
		})
		function onSubmit() {
			try {
				addNewStockGroup()
			} catch (err) {
				alert('can not be saved')
			}
			refreshGroups()
		}
		const changeStockGroup = ref(0)
		const changeNewValue = ref(0)
		function editStockGroup(group) {
			changeStockGroup.value = group
			changeNewValue.value = group
		}
		function onUpdate() {
			updateStockGroup({
				stock_groups: changeStockGroup.value,
				new_value: changeNewValue.value,
				our_company: ourCompany.value,
			})
			refreshGroups()
			changeStockGroup.value = 0
			changeNewValue.value = 0
		}
		// refresh data
		const refreshGroups = () => {
			refetch()
			let listArr
			setTimeout(() => {
				listArr = allStockGroups.value.map((item) => item.stock_groups)
				Store.dispatch('setStockGroupDropDown', listArr)
				Store.dispatch('setKeyStockReload', Store.getters.getKeyStockReload + 1)
			}, 1000)
		}
		refreshGroups()

		return {
			result,
			allStockGroups,
			loading,
			refreshGroups,
			addNewStockGroup,
			newStockGroup,
			deleteStockGroup,
			onSubmit,
			changeStockGroup,
			editStockGroup,
			updateStockGroup,
			changeNewValue,
			onUpdate,
		}
	},
}
</script>

<style scoped>
.list-item {
	margin-top: 10px;
}
.margin-top-15 {
	margin-top: 15px;
}
.small-btn {
	margin: 0 11px 0 0;
}
</style>
