var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutWide',[_c('div',[_c('SubNavbar',{attrs:{"page":"Stock"}})],1),_c('section',[_c('b-button',{staticClass:"is-success",staticStyle:{"width":"250px"},on:{"click":_vm.refresh}},[_vm._v("Refresh the list ")]),_c('div',{staticStyle:{"margin-top":"30px","margin-bottom":"30px"}},[(_vm.loading)?_c('div',{staticClass:"loader"}):_vm._e()]),(!_vm.loading && _vm.showTable)?_c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"styleClass":"vgt-table striped bordered","theme":"black-rhino","fixed-header":true,"search-options":{
					enabled: true,
				},"sort-options":_vm.sortOptions,"pagination-options":{
					enabled: true,
					mode: 'records',
					perPage: 10,
					position: 'bottom',
					perPageDropdown: [10, 15, 20, 100],
					dropdownAllowAll: false,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}},on:{"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'stockId')?_c('span',[_c('p',{staticClass:"text-bold text-align-center"},[_vm._v(" "+_vm._s(props.row.stockId)+" ")])]):(props.column.field === 'digitalInventory')?_c('span',[_c('p',{staticClass:"text-bold text-align-right text-green"},[_vm._v(" "+_vm._s(props.row.digitalInventory)+" ")])]):(props.column.field === 'stockAlarmAmount')?_c('span',[_c('p',{staticClass:"text-bold text-align-right text-red"},[_vm._v(" "+_vm._s(props.row.stockAlarmAmount)+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}],null,false,1684921294)}),_c('download-csv',{attrs:{"data":_vm.rows}},[_c('div',{staticClass:"margin-bottom-30 margin-top-30"},[_c('b-button',{staticClass:"is-success"},[_vm._v(" Download the Insufficient Stock Items List ")])],1)])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }