<!-- @format -->

<template>
	<LayoutWide>
		<div>
			<SubNavbar page="Stock" />
		</div>
		<section>
			<b-button class="is-success" @click="refresh" style="width: 250px"
				>Refresh the list
			</b-button>
			<div style="margin-top: 30px; margin-bottom: 30px">
				<div v-if="loading" class="loader"></div>
			</div>
			<div v-if="!loading && showTable">
				<vue-good-table
					:columns="columns"
					:rows="rows"
					styleClass="vgt-table striped bordered"
					theme="black-rhino"
					:fixed-header="true"
					@on-cell-click="onCellClick"
					:search-options="{
						enabled: true,
					}"
					:sort-options="sortOptions"
					:pagination-options="{
						enabled: true,
						mode: 'records',
						perPage: 10,
						position: 'bottom',
						perPageDropdown: [10, 15, 20, 100],
						dropdownAllowAll: false,
						setCurrentPage: 1,
						jumpFirstOrLast: true,
						firstLabel: 'First',
						lastLabel: 'Last',
						nextLabel: 'next',
						prevLabel: 'prev',
						rowsPerPageLabel: 'Rows per page',
						ofLabel: 'of',
						pageLabel: 'page', // for 'pages' mode
						allLabel: 'All',
					}"
				>
					<template slot="table-row" slot-scope="props">
						<span v-if="props.column.field === 'stockId'">
							<p class="text-bold text-align-center">
								{{ props.row.stockId }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'digitalInventory'">
							<p class="text-bold text-align-right text-green">
								{{ props.row.digitalInventory }}
							</p>
						</span>
						<span v-else-if="props.column.field === 'stockAlarmAmount'">
							<p class="text-bold text-align-right text-red">
								{{ props.row.stockAlarmAmount }}
							</p>
						</span>
						<span v-else>
							{{ props.formattedRow[props.column.field] }}
						</span>
					</template>
				</vue-good-table>
				<download-csv :data="rows">
					<div class="margin-bottom-30 margin-top-30">
						<b-button class="is-success">
							Download the Insufficient Stock Items List
						</b-button>
					</div>
				</download-csv>
			</div>
		</section>
	</LayoutWide>
</template>

<script>
import Store from '@/store'
import { reactive, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import SearchInsufficientStockItemsQuery from '@/_srcv2/pages/stock/graphql/searchInsufficientStockItems.query.gql'
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import useInsufficientStockItemState from '@/_srcv2/pages/stock/layouts/insufficient-stock/useInsufficientStockItemState.js'

export default {
	name: 'InsufficientSearchStockItem',
	components: {
		SubNavbar,
	},
	setup() {
		const showTable = ref(false)
		const { columns } = useInsufficientStockItemState()
		const sortOptions = {
			enabled: true,
			initialSortBy: { field: 'stockId', type: 'asc' },
		}
		const onCellClick = (params) => {
			if (params.column.field === 'stockId') {
				console.log(params.row)
			}
		}
		const onRowDoubleClick = (params) => {
			console.log(params.row)
		}
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { onResult, loading, refetch } = useQuery(
			SearchInsufficientStockItemsQuery,
			() => ({
				company_id: Store.getters.getUserCurrentCompany,
			}),
			options,
		)
		// todo -----------------------------------------------------
		const rows = ref([])
		onResult((result) => {
			console.log('on result', result)
			let myArr = result.data.stock.map((item) => {
				return {
					stockId: item.stock_id,
					stockItemName: item.stock_name,
					digitalInventory: item.stock_amount,
					stockAlarmAmount: item.stock_alarm,
					alarmMessage: item.alarm_message,
				}
			})
			rows.value = myArr.filter(
				(item) => item.alarmMessage === 'insufficient stock',
			)
			showTable.value = true
			console.log(rows.value)
		})

		// todo ----------------------------------------------------
		const refresh = () => {
			showTable.value = false
			refetch().then((result) => {
				console.log('refetch result', result)
				let myArr = result.data.stock.map((item) => {
					return {
						stockId: item.stock_id,
						stockItemName: item.stock_name,
						digitalInventory: item.stock_amount,
						stockAlarmAmount: item.stock_alarm,
						alarmMessage: item.alarm_message,
					}
				})
				rows.value = myArr.filter(
					(item) => item.alarmMessage === 'insufficient stock',
				)
				console.log('rows.value', rows.value)
				showTable.value = true
			})
		}
		// -----------------------------------------------------------------------------------
		return {
			showTable,
			loading,
			refresh,
			columns,
			rows,
			sortOptions,
			onCellClick,
			onRowDoubleClick,
		}
	},
}
</script>

<style scoped>
.list-item {
	margin-top: 10px;
}
.small-btn {
	margin: 0 11px 0 0;
}
.alarm {
	color: red;
}
.store {
	color: green;
}
.loader {
	border: 16px solid #f3f3f3;
	border-radius: 50%;
	border-top: 16px solid #3498db;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
