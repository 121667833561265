<template>
	<div>
		<section v-if="changeVatRate === 0">
			<span><b>Add new VAT Rate</b></span>
			<b-field>
				<b-input
					placeholder="Enter new rate"
					type="number"
					required
					validation-message="Field is required and the type must be number"
					v-model="newVatRate"
					@keyup.native.enter="onSubmit()"
				>
				</b-input>
			</b-field>
			<b-button class="is-primary" type="submit" @click="onSubmit()"
				>Add
			</b-button>
		</section>
		<section v-if="changeVatRate !== 0">
			<span><b>Change the VAT Rate</b></span>
			<b-field>
				<b-input
					placeholder="Enter new rate"
					type="number"
					required
					validation-message="Field is required and the type must be number"
					v-model="changeVatRate"
					@keyup.native.enter="onUpdate()"
				>
				</b-input>
			</b-field>
			<b-button class="is-primary" inverted type="submit" @click="onUpdate()"
				>Change
			</b-button>
		</section>
		<div>
			<h2 v-if="loading">Loading...</h2>
		</div>
		<div class="margin-top-15">
			<span><b>List of the VAT Rates</b></span>
			<ul v-if="allVatRates">
				<li v-for="v of allVatRates" :key="v.moms_id" class="list-item">
					<b-button
						class="is-primary small-btn"
						outlined
						size="is-small"
						@click="deleteVatRate({ moms_id: v.moms_id }) && refreshGroups()"
					>
						Delete
					</b-button>
					<b-button
						class="is-primary small-btn"
						outlined
						size="is-small"
						@click="editVatRate(v.moms_id, v.moms_percent)"
					>
						Edit
					</b-button>
					<b>VAT Rate:</b> &nbsp; {{ v.moms_percent }} &nbsp; %
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/composition-api'

import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import GetAllVatRates from '@/_srcv2/pages/stock/graphql/getAllVatRates.query.gql'
import addVatRateMutation from '@/_srcv2/pages/stock/graphql/addVatRate.mutation.gql'
import deleteVatRateMutation from '@/_srcv2/pages/stock/graphql/deleteVatRate.mutation.gql'
import updateVatRateMutation from '@/_srcv2/pages/stock/graphql/updateVatRate.mutation.gql'

import Store from '@/store'

export default {
	name: 'vat-rates',
	setup() {
		const { result, loading, refetch } = useQuery(GetAllVatRates)
		const allVatRates = useResult(result, [])
		const newVatRate = ref('')
		const { mutate: deleteVatRate } = useMutation(deleteVatRateMutation)
		const { mutate: updateVatRate } = useMutation(updateVatRateMutation)
		const { mutate: addNewVatRate, onDone } = useMutation(
			addVatRateMutation,
			() => ({
				variables: {
					moms_percent: newVatRate.value,
				},
			}),
		)
		onDone(() => {
			newVatRate.value = ''
		})
		function onSubmit() {
			try {
				addNewVatRate()
			} catch (err) {
				alert('can not be saved')
			}
			refreshGroups()
		}
		const changeVatRate = ref(0)
		const changeVatId = ref(0)
		function editVatRate(id, rate) {
			changeVatId.value = id
			changeVatRate.value = rate
		}
		function onUpdate() {
			updateVatRate({
				moms_id: changeVatId.value,
				moms_percent: changeVatRate.value,
			})
			refreshGroups()
			changeVatId.value = 0
			changeVatRate.value = 0
		}
		// refresh data
		const refreshGroups = () => {
			refetch()
			let listArr
			setTimeout(() => {
				listArr = allVatRates.value.map((item) => item.moms_percent)
				Store.dispatch('setStockVatDropDown', listArr)
				Store.dispatch('setKeyStockReload', Store.getters.getKeyStockReload + 1)
			}, 1000)
		}
		refreshGroups()
		return {
			result,
			allVatRates,
			loading,
			refreshGroups,
			addNewVatRate,
			newVatRate,
			deleteVatRate,
			onSubmit,
			changeVatRate,
			editVatRate,
			updateVatRate,
			changeVatId,
			onUpdate,
		}
	},
}
</script>

<style scoped>
.list-item {
	margin-top: 10px;
}
.margin-top-15 {
	margin-top: 15px;
}
.small-btn {
	margin: 0 11px 0 0;
}
</style>
